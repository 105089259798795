import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';

const Page = () => {
  return (
    <Layout>
      <div className='darknav'>
        <Navbar />
      </div>
      <PageBanner pageTitle='404' homePageText='Home' homePageUrl='/' activePageText='Opps!' />

      <div className='container pb-100'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='text-center'>Sorry, we can't find that page!</h3>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  );
};

export default Page;
